// Vanilla JS

// Homepage

// Set height of hero main section
var window_width = $(window).width();

// Only run these if the user is on the homepage
var header_nav = document.getElementById('header-nav').clientHeight;

if (header_nav === null && window_width > 767) {

    var header_row_02 = document.getElementById('header-row-02').clientHeight;
    var header_row_03 = document.getElementById('header-row-03').clientHeight;
    var header_row_04 = document.getElementById('header-row-04').clientHeight;

    var header_text = window.innerHeight - header_nav - header_row_03 - header_row_04;

    document.getElementById("header-row-02").style.minHeight = header_text + 'px';
    
}

function goToByScroll(id){
	$('html,body').animate({scrollTop: $("#"+id).offset().top},'slow');
}


function checkIt(elementName, index) {
    currentValue = $('input:radio[name=' + elementName + ']')[index].checked;
    $("input:radio[name=" + elementName + "]")[index].checked = !currentValue;
    updatePreview();

}
			
function same_height(target) {

    //target = "#" + target + " > div";

    // reset height of divs
    $(target).height("auto");

    var max_height = 0;

    $(target).each(function () {

        if ($(this).height() > max_height) max_height = $(this).height();
        // Need to get the offset height so that it only applies the same height to divs in the same row

        // Create an array and store the heights of each div in it ( get the tallest height and make all in that row the same

        // Allocate the heights one at a time to each element.

    });

    $(target).css("height", max_height);  

}


// Run jquery scripts when ready
(function ($) {

    var window_width = $(window).width();

	// - - Youtube popup video player (Homepage)
    $(".youtube-popup").YouTubePopup({
        autoplay: 0,
        'theme': 'dark',
        'showBorder': false,
        'youtubeId': 'AKmb33GALuc',
    });


    // - - Slick Slider - -
    $('#how-to-use-slideshow').slick({

        slidesToShow: 1,
        dots: true,
        lazyLoad: 'progressive',
        autoplay: true,
		autoplaySpeed: 4000,
    });

    // - - Parallax - -
    if (window_width > 767) {

        $.stellar({
            horizontalScrolling: false
        });

    }

    // Next section link
    $(".next-section a").click(function (event) {

        event.preventDefault();
        var next_ID = $(this).attr('data-target');
        goToByScroll(next_ID);

    });
    
	$(".home-header-center a").click( function(event) {
	
		var target=$(this).attr("href");
		
		if (target.substring(0,1)=="#") {
		
			event.preventDefault();
			goToByScroll(target.substring(1));
		
		}			
	
	});

    // Faqs page
    $('.faqs li').on('click', function () {

        if ($(this).hasClass('current-question')) { // Current Question

            $('.faqs li')
                .removeClass('current-question')
                .next(".answer").slideUp();


        } else { // Not Current Question
            $('.faqs li')
                .removeClass('current-question');
            $(this).addClass('current-question')
                .$(this + ' .answer').slideDown();

        }

    });

    // Sync heights of each of the following groups of elements
    window.onload = function (event) {

        if (window_width > 767) { // Only run on larger screens (non mobile)
            
		    // Three classes that  can be used to sync heights.
			same_height('.same-height-1');
			same_height('.same-height-2');
			same_height('.same-height-3');

        }

    };

    // Sync heights on resize as well
    window.onresize = function (event) {

        if (window_width > 767) { // Only run on larger screens (non mobile)

		    // Three classes that  can be used to sync heights.
			same_height('.same-height-1');
			same_height('.same-height-2');
			same_height('.same-height-3');

        }

    };

    // Manages the interactivity of the option data grid form element
    $(".option-grid span").click(function () {

        var title = $(this).attr('title');
        var parent = $(this).closest("div");
        var target = $(this).closest("div").attr("data-target");

        $("#" + target).val(title);

        // Remove the selected background from previous icon
        $(parent).children('span').removeClass('current');

        // Add it to the current selected icon
        $(this).addClass('current');
        var icon_value = $(this).attr('data-val');
        $('#icon').val(icon_value);
        console.log(icon_value);
    });

    $(".has-children a").click(function (event) {
        event.preventDefault();
        $(this).next('ul').slideToggle();
    });


    // Multi-add more links

    var scntDiv = $('#multi-links');

    var i = $('#multi-links .multi-link').size() + 1;

    $('#multi-add-link').click(function (event) {
        event.preventDefault();
        $('<div class="multi-link"><div class="row"><div class="col-xs-2"><label for="create_link_name_' + i + '">' + i + '. Link Name<label></div><div class="col-xs-5"><input type="text" id="create_link_name_' + i + '" value=""></div><div class="col-xs-5"><button type="button" class="remove-link btn-small btn-warning">Remove Link</button></div></div><div class="row"><div class="col-xs-2">URL</div><div class="col-xs-5"><input type="text" id="create_link_url_' + i + '" placeholder="http://" value=""><div class="sub-text">Example: <span class="text_orange_red">http://shomi.me</span></div><div class="col-xs-5"></div></div></div>').appendTo(scntDiv);
        i++;
        return false;
    });

    // Slide up user alerts after 4 seconds
    $(".alert").delay(6000).slideUp(500);

    // Target your .container, .wrapper, .post, etc.
    $(".fitvids").fitVids();

    $(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
        event.preventDefault();
        $(this).ekkoLightbox();
    });
    
	$("a").click( function(event) {
						
		var target=$(this).attr("href");
						
		if (target.substring(0,1)=="#") {
							
			event.preventDefault();
			goToByScroll(target.substring(1));
							
		}			
						
	});
     

}(jQuery));