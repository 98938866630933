/*! 
 * Sitewide functions
 */
(function ($) { // start our namespace

    $(function () {

        // Attach scrollspy
        $('body').scrollspy({target: 'nav'});

        // Animate the link clicks
        $("nav ul li a[href^='#']").on('click', function (e) {
            e.preventDefault();
            $('html, body').animate({scrollTop: $(this.hash).offset().top}, 300);
            // edit: Opera and IE requires the "html" elm. animated
        });

        // Launch the homepage video, but stop it playing when you close the modal
        $('.demo-video').click(function () {
            var src = 'https://www.youtube-nocookie.com/embed/ZlDJS6KI-GU?rel=0'; 
            $('#demo-video').modal('show');
            $('#demo-video iframe').attr('src', src);
            return false;

        });

        $('#demo-video button').click(function () {
            $('#demo-video iframe').removeAttr('src');
        });

        function SelectText(element) {
            var doc = document, text = doc.getElementById(element), range, selection;
            if (doc.body.createTextRange) {
                range = document.body.createTextRange();
                range.moveToElementText(text);
                range.select();
            } else if (window.getSelection) {
                selection = window.getSelection();
                range = document.createRange();
                range.selectNodeContents(text);
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }

        $('#shomi-select').click(function () {
            SelectText('shomi-font');
        });

        $('[data-toggle="popover"]').popover({
            trigger: 'hover',
            'placement': 'top'
        });
    });


})(jQuery);  // end namespace